<template>
  <div>
    <el-button
      v-throttle
      size="medium"
      @click="
        () => {
          this.$router.back()
        }
      "
      >返回</el-button
    >
    <el-button v-throttle size="medium" type="primary">添加步骤</el-button>
    <el-row>
      <!-- 表单 -->
      <el-table
        header-align="center"
        border
        :header-cell-style="{ background: '#DFE6EC' }"
        :data="tableData"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          align="center"
          type="selection"
          width="55"
        ></el-table-column>
        <el-table-column
          align="center"
          type="index"
          width="55"
          label="序号"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="a"
          label="步骤ID"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="a"
          label="步骤名称"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="a"
          width="80px"
          label="分值"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="a"
          label="评分细则"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="a"
          label="错误解析"
        ></el-table-column>
        <el-table-column align="center" label="步骤配图"></el-table-column>
        <el-table-column align="center" label="操作"></el-table-column>
      </el-table>
    </el-row>
  </div>
</template>

<script>
import {
  getAIConfigPage,
  getAIConfigItem,
  getAIConfigExam,
} from '@/api/scoreAI/experimentManagement.js'
export default {
  name: 'editingStepExperimentManagement',
  data() {
    return {
      aiOperationQuestionId: '',
      FormData: [],
    }
  },
  created() {
    this.aiOperationQuestionId = Number(this.$route.query.aiOperationQuestionId)
  },
  async mounted() {
    // await this.getAIConfigItemFunc(this.aiOperationQuestionId)
  },
  methods: {
    // 根据Ai考题id展示所有评分项
    async getAIConfigItemFunc(val) {
      await getAIConfigItem(val)
        .then((res) => {
          console.log('根据Ai考题id展示所有评分项', res)
          if (res.success) {
            this.FormData = res.data
          }
        })
        .catch((err) => {})
    },
    // 步骤配图
    // 关联评分点
    // 编辑
    // 删除
  },
}
</script>

<style></style>
