import { request, noTimeOutReq } from '@/utils/request.js'

// 展示全部AI考题数量
export function getSubjectCount (data) {
  return noTimeOutReq({
    url: `/ai/operation/config/get/subject/count`,
    method: 'post',
    data
  })
}

//导入配置文件
export function importAIConfig (data) {
  return noTimeOutReq({
    url: `/ai/operation/config/add/config/import`,
    method: 'post',
    data
  })
}
//刷新Ai处理器
export function flushedAI () {
  return noTimeOutReq({
    url: `/ai/operation/config/refresh/handler/config`,
    method: 'post',
  })
}
//分页展示AI配置
export function getAIConfigPage (pageIndex, pageSize, data) {
  return request({
    url: `/ai/operation/config/page/${pageIndex}/${pageSize}/list`,
    method: 'post',
    data
  })
}
//展示所有AI配置
export function getAIConfigAllList () {
  return request({
    url: `/ai/operation/config/list`,
    method: 'post',
  })
}

//根据Ai考题id展示所有评分项
export function getAIConfigItem (aiOperationQuestionId) {
  return request({
    url: `/ai/operation/config/item/list/${aiOperationQuestionId}`,
    method: 'post',
  })
}
//获取AI配置详情
export function getAIConfigItemList (aiOperationQuestionId) {
  return request({
    url: `/ai/operation/config/get/config/${aiOperationQuestionId}`,
    method: 'post',
  })
}

//获取AI配置详情,根据考题id
export function getAIConfigExam (examQuestionOperationTestId) {
  return request({
    url: `/ai/operation/config/get/config/by/exam/${examQuestionOperationTestId}`,
    method: 'post',
  })
}
//获取AI考题是否配置,根据考题id
export function getAIIfConfig (examQuestionOperationTestId) {
  return request({
    url: `/ai/operation/config/get/config/open/by/exam/${examQuestionOperationTestId}`,
    method: 'post',
  })
}

// 删除AI配置
export function deleteAIInfo (data) {
  return request({
    url: `/ai/operation/config/config/delete`,
    method: 'post',
    data
  })
}

// 编辑
// 获取AI考题详情
export function getAIInfo (aiOperationQuestionId) {
  return request({
    url: `/ai/operation/info/get/info/${aiOperationQuestionId}`,
    method: 'post',
  })
}
// 更新AI考题详情
export function uploadAIInfo (data) {
  return request({
    url: `/ai/operation/info/update/info`,
    method: 'post',
    data
  })
}


// 关联
// 展示所有未被关联的考试考试
export function getAINotRelevancyInfo (data) {
  return request({
    url: `/ai/operation/config/exam/question/operation/list`,
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data' },
    data
  })
}
// 取消Ai考题的关联
export function cancelRelevancy (data) {
  return request({
    url: `/ai/operation/config/delete/config/associate`,
    method: 'post',
    data
  })
}
// 展示考试考题详细信息
export function getRelevancyData (examQuestionOperationTestId) {
  return request({
    url: `/ai/operation/config/exam/question/operation/get/${examQuestionOperationTestId}`,
    method: 'post',
  })
}


// 关联Ai考题和考试考题，aiOperationQuestionId-ai考题，examQuestionOperationTestId-考试考题
export function setRelevancy (data) {
  return request({
    url: `/ai/operation/config/update/config/associate`,
    method: 'post',
    data
  })
}



// 开启,关闭考题AI评分
export function switchAiOperation (data) {
  return request({
    url: `/ai/operation/config/delete/config/enable`,
    method: 'post',
    data
  })
}
